// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-borrado-gdpr-js": () => import("./../../../src/pages/legal/borrado-gdpr.js" /* webpackChunkName: "component---src-pages-legal-borrado-gdpr-js" */),
  "component---src-pages-legal-condiciones-de-uso-js": () => import("./../../../src/pages/legal/condiciones-de-uso.js" /* webpackChunkName: "component---src-pages-legal-condiciones-de-uso-js" */),
  "component---src-pages-negocios-js": () => import("./../../../src/pages/negocios.js" /* webpackChunkName: "component---src-pages-negocios-js" */),
  "component---src-pages-users-activation-failed-js": () => import("./../../../src/pages/users/activation/failed.js" /* webpackChunkName: "component---src-pages-users-activation-failed-js" */),
  "component---src-pages-users-activation-success-js": () => import("./../../../src/pages/users/activation/success.js" /* webpackChunkName: "component---src-pages-users-activation-success-js" */)
}

